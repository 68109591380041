
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
	MainLayout, SubpageHeader, createMetaData, ContactForm, GoogleMaps
} from '../components';

const PageContact = () => {
	const { pageContactJson: json } = useStaticQuery(graphql`
		query {
			pageContactJson {
				meta_description
				meta_keywords
				meta_title
				permalink
				title
			}
		}
	`);
	const { site, page, seo } = createMetaData(json);
	return (
		<MainLayout site={site} page={page} seo={seo}>
			<SubpageHeader page={page} />
			<section id='contact-section'>
				<div className='container'>
					<div className='contact-outer'>
						<ContactForm site={site} />
						<GoogleMaps />
					</div>
				</div>
			</section>
		</MainLayout>
	);
};

export default PageContact;
